.topFooterContainer 
{
  background-color: #333333;
  color: white;
  padding: 1.25rem 0 0.75rem;
  height: 520px;
}
.bottomFooterContainer 
{
  background-color: #5c5c5c;
  color: white;
  padding: 0.5rem 0;
  margin-top: -0.5%;
}
.u2img 
{
  min-height: 62px;
  min-width: 400px;
  margin-left: -60%;
  position: relative;
  bottom: 10px;
  background-color: white;
  //margin-top: 32%;
}
.imgWrapper 
{
  margin-top: -2rem;
  margin-left: -15%;
  position: relative;
  top: 12%;
  //background-color: white;
  img 
  {
    object-fit: contain;
    height: auto;
  }
}
.middleFooter 
{
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  h2 
  {
    font-weight: 500;
    font-size: 1.75rem;
    color: #fff;
  }
  svg 
  {
    height: 1.3125rem;
    width: 1.625rem;
    color: white;
    text-align: center;
    align-items: center;
  }
  .locationIconBlocks
  {
    width: 2.625rem;
    //height: 4.8125rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
    min-height: 80%;
  }
  .locationIconBlock 
  {
    width: 2.625rem;
    height: 2.8125rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }
  .locationBlock 
  {
    width: 17rem;
    //height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 0.5rem;
    font-size: 15px;
    min-height: 80%;
  }
  .locationBlocks 
  {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: left;
    padding: 0.5rem;
    font-size:15px;
  }
  .locationBlock, a
  {
    width: 17rem;
    height: 4.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    align-items: justify;
    padding: 0.5rem;
  }
  .locationInfo 
  {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
  .locationInfo, a 
  {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    align-items: justify;
    padding: 0.5rem;
    font-size: 15px;
    color:white;
  }
}
.topFooter 
{
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto 0.5rem;
  justify-content: center; // space-between
  width: 80%;
  h2 
  {
    color: white;
    font-weight: 600;
    font-size: 3rem;
  }
  .cin
  {
    color: white;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    top: -12px; //top; 62px;
    //left: 30%;
  }
  :global(.ant-image) 
  {
    width: 9.1875rem;
    height: 3.3125rem;
    margin: 0 0.5rem;
  }
}
.iconsWrapper 
{
  display: flex;
  padding-top: 2rem;
  a 
  {
    padding: 0 0.5rem;
    width: 35px;
    height: 32px;
    background: #4a4a4a;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
}
.listTitle 
{
  color: #fff;
  margin-bottom: 1.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 10px;
}
.listTitles 
{
  color: #fff;
  margin-bottom: 1.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-left: -50px;
  margin-top: 10px;
}
.footerList 
{
  margin: 0;
  padding: 0;
  margin-left: 7px;
  li 
  {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin-bottom: 14px;
    list-style-type: none;
    color: #fff;
    cursor: pointer;
  }
  .links, li
  {
    margin:0;
    padding:0;
    background: none;
    color:white;
  }
}
.footerLists 
{
  margin: 0;
  padding: 0;
  margin-left: -46px;
  li 
  {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin-bottom: 14px;
    list-style-type: none;
    color: #fff;
    cursor: pointer;
  }
}
.bottomFooter 
{
  width: 94%;
  margin: 0 auto;
  :global(.ant-btn) 
  {
    color: white;
    span 
    {
      text-decoration-line: underline;
    }
  }
}

@media (max-width: 2600px)
{
  .middleFooter
  {
    .locationBlock 
    {
      width: 21rem;
      height: 4.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }
    .locationIconBlocks
    {
      width: 2.625rem;
      height: 4.8125rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }
    .locationInfo 
    {
      width: 21rem;
      height: 2.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      //justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }
    .locationBlocks 
    {
      width: 21rem;
      height: 2.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      //justify-content: center;
      align-items: left;
      padding: 0.5rem;
      font-size:15px;
    }
  }
}

@media (min-width: 1700px) and (max-width: 2400px)
{
  .wrapper
  {
    position: relative;
    height: 1150px;
  }
  .u2img 
  {
    min-height: 62px;
    min-width: 400px;
    //margin-left: -200%;
    position: relative;
    left: -500px;
    bottom: 10px;
    background-color: white;
    //margin-top: 32%;
  }
  .topFooterContainer
  {
    position: relative;
    right: 50px;
  }
  .topFooter
  {
    h2
    {
      color: white;
      font-weight: 600;
      font-size: 3rem;
      position: relative;
      right: 190%;
    }
    .cin
    {
      color: white;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      right: 190%;
    }
  }
  .middleFooter
  {
    h2
    {
      color: white;
      font-weight: 500;
      font-size: 1.75rem;
      position: relative;
      right: 19%;
    }
  }
  .iconsWrapper
  {
    display: flex;
    padding-top: 3rem;
  }
  .listTitle 
  {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  right: 80%;
  bottom: 3px;
  }
  .footerList
  {
    position: relative;
    right: 80%;
  }
  .listTitles
  {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  right: 125%;
  bottom: 3px;
  }
  .footerLists
  {
    position: relative;
    right: 125%;
  }
  .imgWrapper
  {
    position: relative;
    right: 170%;
    img
    {
      object-fit: contain;
      height: auto;
    }
  }
}

@media (min-width: 1500px) and (max-width: 1600px)
{
  .wrapper
  {
    min-height: 2630px;
  }
  .u2img 
  {
    min-height: 62px;
    min-width: 400px;
    position: relative;
    left: -150px;
    bottom: 10px;
    background-color: white;
  }
  .topFooter
  {
    h2
    {
      color: white;
      font-weight: 600;
      font-size: 3rem;
      position: relative;
      right: 70%;
    }
    .cin
    {
      color: white;
      font-size: 20px;
      font-weight: 500;
      position: relative;
      right: 70%;
    }
  }
  .middleFooter
  {
    h2
    {
      color: white;
      font-weight: 500;
      font-size: 1.75rem;
      position: relative;
      right: 8%;
    }
  }
  .iconsWrapper
  {
    display: flex;
    padding-top: 2rem;
  }
  .listTitle 
  {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  right: 30%;
  bottom: 3px;
  }
  .footerList
  {
    position: relative;
    right: 30%;
  }
  .listTitles
  {
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  right: 55%;
  bottom: 3px;
  }
  .footerLists
  {
    position: relative;
    right: 55%;
  }
  .imgWrapper
  {
    position: relative;
    right: 80%;
    img
    {
      object-fit: contain;
      height: auto;
    }
  }
}

@media (min-width: 1200px) and (max-width:1300px)
{
  .bottomFooterContainer
  {
    min-height: 300px;
  }
}

@media (max-width: 1024px) 
{
  .topFooterContainer
  {
    height:1300px; //800px;
    width: 100%;
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0rem;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: -1.5%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 140%;
    //margin-left:100%;
    width: 100%;
    color: #fff;
    bottom: 195%; //1.875rem;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 10px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 137%;
      bottom: 280px;
    }
  }
  .middleFooter 
  {
    h2 
    {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: auto;
    max-height: auto;
    //margin-top: -15%;
    position:relative;
    bottom: 75%;
    left: 5%;
    img 
    {
      object-fit: contain;
      height: auto;
    }
  }
}

@media (min-width: 820px) and (max-width: 912px)
{
  .topFooterContainer
  {
    min-height: 1400px;
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 6.5%;
    }
  }
  .listTitles
  {
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 33px;
    margin-bottom: 52px;
    //position:relative;
    //left: 148%;
    //bottom: 15px; //1.875rem;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 145%;
      bottom: 278px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    width: 180px;
    max-height: 280px;
    position:relative;
    top: -75%;
    margin-right: 45px;
    img 
    {
      object-fit: contain;
      min-width: 75px;
      height: 75px;
    }
  }
}

@media (min-width:610px) and (max-width: 768px)
{
  .topFooterContainer
  {
    min-height: 1000px;
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 6.5%;
    }
  }
  .listTitles
  {
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-bottom: 6px;
    //position:relative;
    //left: 148%;
    //bottom: 15px; //1.875rem;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 145%;
      bottom: 278px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    width: 180px;
    max-height: 110px;
    position:relative;
    top: -55%;
    margin-right: 1px;
    img 
    {
      object-fit: contain;
      min-width: 75px;
      height: 75px;
    }
  }
}

@media (min-width:500px) and (max-width: 580px)
{
  .topFooterContainer
  {
    min-height: 500px;
  }
  .u2img 
  {
    position: relative;
    left: -40px;
  }
  .locationBlock
  {
    min-height: 300px;
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
    top: 25%;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 6.5%;
      top: 120%;
    }
  }
  .listTitles
  {
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-bottom: 3px;
    //position:relative;
    //left: 148%;
    //bottom: 15px; //1.875rem;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 145%;
      top: -170px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    width: 180px;
    max-height: 200px;
    position:relative;
    top: -95%;
    margin-right: 15px;
    img 
    {
      object-fit: contain;
      min-width: 75px;
      height: 85px;
    }
  }
}

@media (min-width: 370px) and (max-width: 480px)
{
  .topFooterContainer
  {
    min-height: 1000px;
  }
  .u2img
  {
    position: relative;
    left: -22px;
    min-width: 250%; 
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
    top: 25%;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 6.5%;
      top: 120%;
    }
  }
  h4.listTitles
  {
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    position: absolute;
    top: -251%;
    left: 41%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 145%;
      top: -148px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    width: 180px;
    height: 250px;
    position:relative;
    top: -60%;
    margin-right: 5px;
    img 
    {
      object-fit: contain;
      min-width: 75px;
      height: 85px;
    }
  }
}

@media (max-width: 360px)
{
  .topFooterContainer
  {
    min-height: 900px;
  }
  .u2img
  {
    position: relative;
    left: 14px;
    min-width: 200%; 
  }
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
    top: 25%;
  }
  .footerList 
  {
    li 
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      top: 120%;
    }
  }
  h4.listTitles
  {
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-bottom: 28px;
    top: -251%;
    left: 41%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 145%;
      top: -148px;
    }
  }
  .middleFooter
  {
    .locationIconBlocks
    {
      width: 2.625rem;
      height: 40px;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
      min-height: 80%;
    }
  .locationBlock 
    {
      width: 17rem;
      min-height: 20px;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      //justify-content: center;
      align-items: center;
      padding: 0.5rem;
      font-size: 14px;
      min-height: 80%;
    }
    .sgs
    {
      position: relative;
      top: 5px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    width: 180px;
    height: 250px;
    position:relative;
    top: -60%;
    margin-right: 5px;
    img 
    {
      object-fit: contain;
      min-width: 75px;
      height: 85px;
    }
  }
}

@media screen and (max-width: 768px) 
{
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
    position: relative;
    left: 6%;
  }
  .footerList {
    li {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 6%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 148%;
    width: 100%;
    color: #fff;
    bottom: 196%; //1.875rem;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 10px;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 144%;
      bottom: 278px;
    }
  }
  .topFooterContainer
  {
    height:850px;
    width: 100%;
  }
  .topFooter {
    h2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    width: 90%;
  }
  .middleFooter {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:1.5%;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 65%;
    max-height: 100%;
    //margin-top: -15%;
    position:relative;
    bottom: 75%;
    left: 1%;
    img {
      object-fit: contain;
      height: auto;
    }
  }

}

@media screen and (max-width: 576px) {
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem -1.7rem;
  }
  .footerList {
    li {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: -1%;
    }
  }
  .listTitles
  {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-top: -3%;
    position:relative;
    left: 32%;
    top: -192%;
    //bottom: 185%; //1.875rem;
    //width: 100%;
    //margin-left: 5px;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      //margin-top: 14px;
      left: 40%;
      //top: 10%;
      bottom: 245px;
    }
  }
  .topFooterContainer
  {
    height:900px;
    width: 100%;
  }
  .topFooter {
    h2 {
      margin-bottom: 1rem;
      font-size: 2.25rem;
    }
    width: 90%;
  }
  .middleFooter {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:-11%; //-11%;
    }
    .locationBlock 
    {
      width: 21rem;
      height: 4.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }
    .locationBlocks 
    {
      width: 21rem;
      height: 2.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      //display: flex;
      align-items: center;
      padding: 0.5rem;
    }
    
    .locationIconBlock {
      width: 2.625rem;
      height: 2.5rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }
    .locationBlock
    {
      a 
      {
        width: 14rem;
        height: 2.625rem;
        background: #4a4a4a;
        border-radius: 8px;
        display: flex;
        align-items: justify;
        padding: 0.5rem;
      }
    } 
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 70%;
    //margin-top: -35%;
    position:relative;
    bottom: 135%;
    left: 7%;
    img {
      object-fit: contain;
      height: auto;
    }
  }

}

@media only screen and (max-width:520px)
{
  .middleFooter
  {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:3%; //-11%;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 60%;
    //margin-top: -35%;
    position:relative;
    bottom: 70%;
    left: -20%;
    img {
      object-fit: contain;
      height: auto;
    }
  }
  .listTitle
  {
    position: relative;
    left: 8%;
  }
  .listTitles
  {
    position:absolute;
    left: 41.5%;
    top: -320%;
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 50%;
      bottom: 195px;
    }
  }
  .bottomFooterContainer
  {
    height: 120px;
    .bottomFooter
    {
      width:110%;
      position:relative;
      top: -12%;
      left: -3%;
    }
  }
}

@media only screen and (min-width:600px), (max-width:768px)
{
  .topFooter
  {
    h2
    {
      font-size: 40px;
    }
  }
}
/*
@media only screen and (min-width:600px), (max-width:720px)
{
  
  .middleFooter
  {
    height: 850px;
    h2
    {
      position: relative;
      left: -2%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 143%;
    top: -107%;
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 140%;
      bottom: 380px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 60%;
    //margin-top: -35%;
    position:relative;
    bottom: 70%;
    left: -10%;
    img {
      object-fit: contain;
      height: auto;
    }
  }
}
*/