.contactUsContainer 
{
    max-width: 90%;
    margin: 5rem auto;
    .container 
    {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
    }
    h2 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        text-align: center;
        color: #333;
        margin-bottom: 1.25rem;
    }
    .subTitle 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        text-align: center;
        color: #333333;
        width: 55%;
        margin: 0 auto 2.5rem;
    }
    .formWrapper 
    {
        max-width: 50%;
    }
    .btn 
    {
        font-family: "Poppins";
        padding: 14px 38px;
        display: inline-block;
        background: #ffc410;
        cursor: pointer;
        border: 3px solid #ffe698;
        border-radius: 8px;
        font-size: 18px;
        font-weight: 600;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: center;
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .btn:hover 
    {
        background-color: white;
        border: 1px solid black;
    }
    .imgWrapper 
    {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 50%;
        img 
        {
            width: 100%;
            height: auto;
            position:relative;
            left: 20%;
        }
    }
    input, textarea 
    {
        background-color: #ffc41033;
        border: 1px solid;
        border-color: #ffc41080;
        border-radius: 6px;
        align-items: flex-start;  
        display: flex;
        margin-left: -2.5%;
        min-width: 105%;
      //padding: 12px 12px;
      //height: 52px;
    }
    input:hover,
    input:focus,
    textarea:hover,
    textarea:focus 
    {
        border: 2px solid #ffc410;
        box-shadow: 0 0 0 2px #ffc410;
    }
    :global(.ant-input-group-addon) 
    {
        background-color: #ffc41033;
        border-color: #ffc41080;
        :global(.ant-select-show-arrow) 
        {
            width: fit-content !important;
            :global(.ant-select-selection-item) 
            {
                padding-right: 1.75rem;
            }
        }
    }
    span 
    {
        color: #333;
        font-family: "Poppins";
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
    }
    .firstname
    {
       position:relative;
       left:-1%;
       width: 420px;
    }
    .lastname
    {
       position:relative;
       left:-1%;
       top: -10px;
       width: 420px;
    }
    .phone
    {
        min-width: 415px;
        margin-left: -25px;
        //display: none;
    }
    .email
    {
        min-width: 415px;
        margin-left: -25px;
    }
    .msg
    {
        margin-left: -35px;
        min-width: 395px;
    }
    .map
    {
        position:relative;
        top: 40px;
        width:90%;
        height: 95%; 
    }
}

@media (min-width: 1500px) and (max-width: 1600px)
{
    h2
    {
        position: relative;
        right: 190px;
    }
    .map
    {
        img
        {
            position: relative;
            right: 20%; 
        }
    }
}

@media (min-width: 1700px) and (max-width: 2400px)
{
    .contactUsContainer
    {
        position: relative;
        right: 13%;
    }
    h2 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        text-align: center;
        color: #333;
        margin-bottom: 1.25rem;
        position: relative;
        right: 200px;
    }
    .map
    {
        img
        {
            position: relative;
            right: 26%; 
        }
    }
}

@media (max-width: 1024px) 
{
    .contactUsContainer 
    {
        .formWrapper 
        {
            max-width: 90%;
        }
        //width: 90%;
        .subTitle 
        {
            width: 80%;
        }
        input 
        {
            //min-width: unset;
            min-width: 105%;
            margin-left:-2.5%;
            //flex-wrap:wrap;
        }
        textarea
        {
            margin-left:-2%;
            min-width:105%;
            //flex-wrap:wrap;
        }
        .imgWrapper 
        {
            display: none;
        }
        .map
        {
            display: none;
        }
    }
}

@media (min-width: 768px) and (max-width: 912px) 
{
    .contactUsContainer 
    {
        .formWrapper 
        {
            max-width: 90%;
            position: relative;
            left: 20%;
        }
        .phone
        {
            min-width: 415px;
            margin-left: -25px;
            //display: none;
        }
        .email
        {
            min-width: 415px;
            margin-left: -25px;
        }
        .msg
        {
            margin-left: -35px;
            min-width: 395px;
        }
        // textarea
        // {
        //     margin-left:-2%;
        //     min-width: 105%;
        // }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            width: 117px;
            height: 44px;
            padding: 8px 14px;
            font-size: 14px;
        }
    }
}

// @media (max-width: 768px) 
// {
//     .contactUsContainer 
//     {
//         .formWrapper 
//         {
//             max-width: 90%;
//             position: relative;
//             left: 25%;
//         }
//         textarea
//         {
//             margin-left:-2%;
//             min-width: 105%;
//         }
//         .imgWrapper 
//         {
//             display: none;
//         }
//         .btn 
//         {
//             width: 117px;
//             height: 44px;
//             padding: 8px 14px;
//             font-size: 14px;
//         }
//     }
// }

@media (min-width: 500px) and (max-width: 600px) 
{
    .contactUsContainer 
    {
        .formWrapper 
        {
            width: 150%;
            position: relative;
            left: 28px;
        }
        input
        {
            align-items:flext-start; 
        }
        .firstname
        {
            position:relative;
            min-width: 230%;
            left:3%;
        }
        .lastname
        {
            position:relative;
            left:50px;
            margin-left: 35%;
            bottom:30%;
            min-width: 230%;
        }
        .phone
        {
            min-width: 415px;
            margin-left: -15px;
            //display: none;
        }
        .email
        {
            min-width: 415px;
            margin-left: -15px;
        }
        .msg
        {
            margin-left: -20px;
            min-width: 395px;
        }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            min-width: 120%;
            height: 44px;
            padding: 8px 14px;
            font-size: 14px;
            position: relative;
            left: 30%;
        }
        .map
        {
            display: none;
        }
    }
}

@media (min-width: 370px) and (max-width: 480px) 
{
    .contactUsContainer
    {
        .formWrapper 
        {
            width: 150%;
        }
        input
        {
            align-items:flext-start; 
        }
        .firstname
        {
            position:relative;
            min-width: 230%;
            left:3%;
        }
        .lastname
        {
            position:relative;
            left:50px;
            margin-left: 35%;
            bottom:30%;
            min-width: 230%;
        }
        .phone
        {
            min-width: 300px;
            margin-left: -10px;
            //display: none;
        }
        .email
        {
            margin-left: -10px;
            min-width: 300px;
        }
        .msg
        {
            margin-left: -60px;
            min-width: 285px;
        }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            min-width: 120%;
            height: 44px;
            padding: 8px 14px;
            font-size: 14px;
            position: relative;
            left: 30%;
        }
        .map
        {
            display: none;
        }
    }
}

@media (max-width: 360px) 
{
    .contactUsContainer
    {
        .formWrapper 
        {
            width: 150%;
            position: relative;
            left: 20px;
        }
        input
        {
            align-items:flext-start; 
        }
        .firstname
        {
            position:relative;
            min-width: 230%;
            left:3%;
        }
        .lastname
        {
            position:relative;
            left:50px;
            margin-left: 35%;
            bottom:30%;
            min-width: 230%;
        }
        .phone
        {
            min-width: 280px;
            margin-left: -10px;
            //display: none;
        }
        .email
        {
            margin-left: -10px;
            min-width: 280px;
        }
        .msg
        {
            margin-left: -60px;
            min-width: 270px;
        }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            min-width: 120%;
            height: 44px;
            padding: 8px 14px;
            font-size: 14px;
            position: relative;
            left: 30%;
        }
        .map
        {
            display: none;
        }
    }
}

@media only screen and (max-width:576px)
{
    .contactUsContainer 
    {
        .formWrapper 
        {
            max-width: 90%;
        }
        input
        {
            align-items:flext-start;
        }
        .firstname
        {
            position:relative;
            width: 232px;
            left:2%;
        }
        .lastname
        {
            position:relative;
            left:-34%;
            bottom:30%;
            width: 232px;
        }
        .phone
        {
            position: relative;
            left: 11%;
            width: 40%;
        }
        .email
        {
            position: relative;
            left: 11%;
            width: 40%;
        }
        .msg
        {
            width: 75%;
            height: auto;
            //margin-left:16%;
            position: relative;
            left: 18%;
        }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            width: 50%;
            height: 50%;
            padding: 15px 15px;
            font-size: 20px;
            margin-left: 25%;
        }
    }
}


@media only screen and (max-width:520px)
{
    .contactUsContainer {
        .formWrapper {
            max-width: 90%;
        }
        input
        {
            align-items:flext-start;
        }
        .firstname
        {
            position:relative;
            width: 232px;
            left:2%;
        }
        .lastname
        {
            position:relative;
            left:-34%;
            bottom:30%;
            width: 232px;
        }
        .phone
        {
            position: relative;
            left: 11%;
            width: 50%;
        }
        .email
        {
            position: relative;
            left: 11%;
            width: 50%;
        }
        .msg
        {
            width: 30%;
            height: auto;
        }
        textarea
        {
            margin-left:14%;
        }
        .imgWrapper 
        {
            display: none;
        }
        .btn 
        {
            width: 50%;
            height: 50%;
            padding: 15px 15px;
            font-size: 20px;
            margin-left: 25%;
        }
    }

}

@media only screen and (min-width:768px)
{
    .contactUsContainer 
    {
    .phone
        {
            position: relative;
            left: 9%;
            width: 50%;
        }
    .email
        {
            position: relative;
            left: 9%;
            width: 50%;
        }
    .msg
        {
            position: relative;
            left: 16%;
            width: 30%;
            height: auto;
        }
    }
}


/*
.inputContactUs 
{
    display: inline;
}

@media ( max-width: 1024px) 
{
    .inputContactUs 
    {
        display: none;
    }
}

@media (min-width: 768px) 
{
    .inputContactUs 
    {
        width: 100%;
    }
}
 */
