.container {
  max-width: 94%;
  padding: 1.125rem 2rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}

h1 {
  font-family: "Poppins";
  font-size: 27px;
  font-weight: 900;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 2%;
  margin-left:-1%;
  width: 100%;
  position: relative;
  right: 12%;
}

.logo {
  color: green;
  margin: auto 0;
}

.menuOutlined {
  display: none;
  margin: auto 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.navLink {
  text-decoration: none;
  font-size: 16px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin-left: 5%; //2rem;
  position:relative;
  left:30%;
  top:8%;
}

.langMenu {
  font-size: 16px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin: 0 2rem;
}

.login {
  :global(.ant-btn) {
    width: 110px;
    height: 47px;
    justify-content: center;
    align-items: center;
    padding: 5px 13px;
    background: #ffc410;
    border: 3px solid #ffe698;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  :global(.ant-btn:hover) {
    background: white;
    color: #14113a;
    border: 3px solid #14113a;
  }
}

.mobileNavLink {
  display: none;
}

@media screen and (max-width: 991px) {
  .container {
    padding: 1.125rem 1rem;
  }

  .desktopNavLink {
    display: none;
  }

  .menuOutlined {
    display: block;
  }

  .mobileNavLink {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }

  .navLink {
    margin: 0;
    padding: 1rem 0;
  }

  .login {
    padding-top: 0.5rem;
    
    :global(.ant-btn) {
      padding: 8px;
      margin: 0;
    }
  }

  .langMenu {
    margin: 0;
    padding: 1rem 0;
  }
}

@media only screen and (min-width:320px), (max-width:667)
{
  .container
  {
    h1
    {
      //color:darkcyan;
      margin-right: 10px;
      font-weight:100;
    }
  }
  span
  {
    img
    {
      position:relative;
      left:-20px;
      min-width:100px;
      height:auto;
    }
  }
  
  @media only screen and (max-width:360px)
  {
    .container
    {
      h1
      {
        //color:darkcyan;
        margin-left: -10px;
        font-weight:900px;
        font-size: 25px;
      }
    }
  } 
}

@media only screen and (min-width:768px), (max-width:912px)
{
  .container
  {
    h1
    {
      //color:darkcyan;
      margin-left: 40px;
      font-weight:1000;
      font-size:28px;
      width:180%;
    }
  }
  .desktopNavLink
    {
      margin-right:100px;
      //display:none;
    }
    span
    {
      img
      {
        position:relative;
        left:-8px;
      }
    }
}


@media only screen and (min-width:1000px), (max-width:1300px)
{
  .container
    {
      h1
        {
          //color:darkcyan;
          //margin-left: 25px;
          position: relative;
          right: 60px;
          font-weight:1000;
          font-size:25px;
          width: 180%;
        }
        .desktopNavLink
        {
          position: relative;
          top: 20px;
          //margin-right:100px;
          //display:none;
        }
    }
    
}
/*@media only screen and (min-width:1200px), (max-width:1600px)
{
  .container
    {
      h1
        {
          color:darkcyan;
          margin-left: -25px;
          font-weight:1000;
          font-size:25px;
          width: 180%;
        }
    }
    .navLink
    {
      font-size: 20px;
    }
  .desktopNavLink
    {
      margin-right:230px;
      margin-top: 20px;
    }
    span
    {
      img
      {
        position:relative;
        left:458px;
        //float:left;
        //min-width:100px;
        //height:auto;
      }
    }
} */