.topFooterContainer {
  background-color: #333333;
  color: white;
  padding: 1.25rem 0 0.75rem;
}

.bottomFooterContainer {
  background-color: #5c5c5c;
  color: white;
  padding: 0.5rem 0;
}

.imgWrapper {
  margin-top: -3rem;
  margin-left: -10%;
  img {
    object-fit: contain;
    height: auto;
  }
}

.middleFooter {
  width: 90%;
  margin: 0 auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  h2 {
    font-weight: 500;
    font-size: 1.75rem;
    color: #fff;
  }
  svg {
    height: 1.3125rem;
    width: 1.625rem;
    color: white;
    text-align: center;
    align-items: center;
  }
  .locationIconBlocks
  {
    width: 2.625rem;
    height: 4.8125rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }
  .locationIconBlock 
  {
    width: 2.625rem;
    height: 2.8125rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
  }
  .locationBlock {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 0.5rem;
    font-size: 15px;
  }
  .locationBlocks {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: left;
    padding: 0.5rem;
    font-size:15px;
  }
  .locationBlock, a {
    width: 17rem;
    height: 4.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    align-items: justify;
    padding: 0.5rem;
  }
  .locationInfo {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    //justify-content: center;
    align-items: center;
    padding: 0.5rem;
  }
  .locationInfo, a {
    width: 17rem;
    height: 2.625rem;
    background: #4a4a4a;
    border-radius: 8px;
    display: flex;
    align-items: justify;
    padding: 0.5rem;
    font-size: 15px;
    color:white;
  }
}

.topFooter 
{
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto 0.5rem;
  justify-content: center; // space-between
  width: 80%;
  h2 
  {
    color: white;
    font-weight: 600;
    font-size: 2rem;
  }
  .cin
  {
    color: white;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    top: -12px; //top; 62px;
    //left: 30%;
  }
  :global(.ant-image) {
    width: 9.1875rem;
    height: 3.3125rem;
    margin: 0 0.5rem;
  }
}

.iconsWrapper {
  display: flex;
  padding-top: 1rem;

  a {
    padding: 0 0.5rem;
    width: 35px;
    height: 32px;
    background: #4a4a4a;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }
}

.listTitle {
  color: #fff;
  margin-bottom: 1.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-left: 5px;
  margin-top: 10px;
}

.listTitles {
  color: #fff;
  margin-bottom: 1.875rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin-left: -50px;
  margin-top: 10px;
}

.footerList {
  margin: 0;
  padding: 0;
  margin-left: 7px;

  li {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin-bottom: 14px;
    list-style-type: none;
    color: #fff;
    cursor: pointer;
  }
  .links, li
  {
    margin:0;
    padding:0;
    background: none;
    color:white;
  }
}

.footerLists {
  margin: 0;
  padding: 0;
  margin-left: -46px;

  li {
    font-family: Poppins, sans-serif;
    font-size: 16px;
    margin-bottom: 14px;
    list-style-type: none;
    color: #fff;
    cursor: pointer;
  }
}

.bottomFooter {
  width: 94%;
  margin: 0 auto;
  :global(.ant-btn) {
    color: white;
    span {
      text-decoration-line: underline;
    }
  }
}

@media (max-width: 2600px)
{
  .middleFooter
  {
    .locationBlock 
    {
      width: 17rem;
      height: 5.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }
    .locationIconBlocks
    {
      width: 2.625rem;
      height: 5.8125rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }
  }
}

@media (max-width: 1024px) 
{
  .topFooterContainer
  {
    height:1300px; //800px;
    width: 100%;
  }
  .listTitle {
    margin: 0.1rem 0 0.8rem 0rem;
  }
  .footerList 
  {
    li {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: -1.5%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 140%;
    //margin-left:100%;
    width: 100%;
    color: #fff;
    bottom: 202%; //1.875rem;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 10px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 137%;
      bottom: 295px;
    }
  }
  .middleFooter {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: auto;
    max-height: auto;
    //margin-top: -15%;
    position:relative;
    bottom: 75%;
    left: 5%;
    img {
      object-fit: contain;
      height: auto;
    }
  }

}

@media screen and (max-width: 768px) {
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
  }
  .footerList {
    li {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: -1.5%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 140%;
    width: 100%;
    color: #fff;
    bottom: 202%; //1.875rem;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-left: 5px;
    margin-top: 10px;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 137%;
      bottom: 292px;
    }
  }
  .topFooterContainer
  {
    height:850px;
    width: 100%;
  }
  .topFooter {
    h2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    width: 90%;
  }
  .middleFooter {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:1.5%;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 65%;
    max-height: 100%;
    //margin-top: -15%;
    position:relative;
    bottom: 75%;
    left: 1%;
    img {
      object-fit: contain;
      height: auto;
    }
  }

}

@media screen and (max-width: 576px) {
  .listTitle 
  {
    margin: 0.1rem 0 0.8rem 0;
  }
  .footerList {
    li {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: -1%;
    }
  }
  .listTitles
  {
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
    margin-top: -2.5%;
    position:relative;
    left: 32%;
    top: -192%;
    //bottom: 185%; //1.875rem;
    //width: 100%;
    //margin-left: 5px;
    //margin-left:100%;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      //margin-top: 14px;
      left: 40%;
      //top: 10%;
      bottom: 245px;
    }
  }
  .topFooterContainer
  {
    height:900px;
    width: 100%;
  }
  .topFooter {
    h2 {
      margin-bottom: 1rem;
      font-size: 1.25rem;
    }
    width: 90%;
  }
  .middleFooter {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:-11%; //-11%;
    }
    .locationIconBlocks
    {
      width: 2.625rem;
      height: 4.5125rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }
    .locationBlock 
    {
      width: 17rem;
      height: 4.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.5rem;
    }
    .locationBlocks 
    {
      width: 17rem;
      height: 2.625rem;
      background: #4a4a4a;
      border-radius: 8px;
      //display: flex;
      align-items: center;
      padding: 0.5rem;
    }
    
    .locationIconBlock {
      width: 2.625rem;
      height: 2.5rem;
      background: #4a4a4a;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.5rem;
    }
    .locationBlock
    {
      a 
      {
        width: 14rem;
        height: 2.625rem;
        background: #4a4a4a;
        border-radius: 8px;
        display: flex;
        align-items: justify;
        padding: 0.5rem;
      }
    } 
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 70%;
    //margin-top: -35%;
    position:relative;
    bottom: 135%;
    left: 7%;
    img {
      object-fit: contain;
      height: auto;
    }
  }

}

@media only screen and (max-width:520px)
{
  .middleFooter
  {
    h2 {
      //font-size: 1.4rem;
      font-family: Roboto, sans-serif;
      font-weight: 700;
      font-size: 20px;
      position:relative;
      left:3%; //-11%;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 60%;
    //margin-top: -35%;
    position:relative;
    bottom: 70%;
    left: -20%;
    img {
      object-fit: contain;
      height: auto;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 40%;
    top: -325%;
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 50%;
      bottom: 195px;
    }
  }
  .bottomFooterContainer
  {
    height: 120px;
    .bottomFooter
    {
      width:110%;
      position:relative;
      top: -12%;
      left: -3%;
    }
  }
}

/*
@media only screen and (min-width:600px), (max-width:720px)
{
  
  .middleFooter
  {
    height: 850px;
    h2
    {
      position: relative;
      left: -2%;
    }
  }
  .listTitles
  {
    position:absolute;
    left: 143%;
    top: -107%;
    width: 100%;
    color: #fff;
    font-family: Roboto, sans-serif;
    font-weight: 700;
    font-size: 20px;
  }
  .footerLists
  {
    li
    {
      font-family: Poppins, sans-serif;
      font-size: 14px;
      //margin-bottom: 14px;
      list-style-type: none;
      color: #fff;
      cursor: pointer;
      position:relative;
      left: 140%;
      bottom: 380px;
    }
  }
  .imgWrapper 
  {  
    float:right;
    max-width: 35%;
    max-height: 60%;
    //margin-top: -35%;
    position:relative;
    bottom: 70%;
    left: -10%;
    img {
      object-fit: contain;
      height: auto;
    }
  }
}
*/