.wrapper {
  margin: 5rem 0px;
  position: relative;
}
.imgBg {
  width: 100%;
  position: absolute;
  top: -1px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.imgBgBottom {
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
  img {
    width: 100%;
  }
}
.logo1 {
  position: absolute;
  bottom: 8rem;
  left: -15px;
  img {
    width: 350px;
  }
}
.logo2 {
  position: absolute;
  top: 5rem;
  right: -15px;
  img {
    width: 350px;
  }
}
.img1Container {
  background-color: #ffc410;
  padding: 12rem 0;
}
.contentWrapper {
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 1.25rem;
  h1 {
    font-family: "Poppins";
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    text-align: center;
    margin-left: -10%;
  }
  h4 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 758px;
    margin: 0 auto 20px;
    
  }
}
.teamsSlider 
{
  :global(.slick-prev) {
    //z-index: auto;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: auto;
      opacity: 1;
      display: block;
      position: relative;
      left:900%;
      //margin-left: 500%; //-30px;
      //margin-top: 0;
    }
  }
  :global(.slick-next) {
    z-index: 9999;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn-right.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
      margin-top: -252%;
      margin-left: -35px;
    }
  }
}
.tabsWrapper {
  max-width:1160px;
  margin: 0 auto;
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      background-color: #f5f5f5;
      border-radius: 20px;
      padding: 12px;
      max-width: 60%;
      margin-left: 15%;
      &::before {
        display: none;
      }
      :global(.ant-tabs-nav-wrap) {
        :global(.ant-tabs-tab) {
          :global(.ant-tabs-tab-btn) {
            background-color: #ffffff;
            border-radius: 20px;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            padding: 20px 18px;
          }
          &:global(.ant-tabs-tab-active) {
            :global(.ant-tabs-tab-btn) {
              background-color: #fbcc3f;
              color: black;
            }
          }
        }
        :global(.ant-tabs-ink-bar) {
          display: none;
        }
      }
      :global(.ant-tabs-nav-operations) {
        display: none;
      }
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  background: rgba(251, 204, 63, 0.3);
  border-radius: 20px;
  margin: 40px 10px;
  .details {
    max-width: 521px;

    h4 {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: -30%;
    }
    p {
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: justify;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 20px;
      color: black;
    }
    h3 {
      font-family: "Poppins";
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: -30%;
    }
    span
    {
      font-size: 18px;
      font-weight: 700;
      line-height: 45px;
      position: relative;
      left: 5%;
    }
  }
}
.content 
{
  display: flex;
  justify-content: center;
  background: rgba(251, 204, 63, 0.3);
  border-radius: 20px;
  margin: 40px 10px;
  .detail {
    max-width: 521px;
    p 
    {
      font-family: "Poppins";
      font-size: 20px;
      font-weight: 700;
      line-height: 34px;
      letter-spacing: 0em;
      text-align: justify;
      width: 85%;
      margin-left: -35%;
      margin-bottom: 20px;
      color: black;
    }
    li
    {
      list-style-type: disc;
      font-size: 20px;
      font-weight: 700;
      line-height: 45px;
      position: relative;
      left: 58%;
      bottom: 55%;
    }
  }
}
.imgWrapper{
  position: relative;
  left: 4%;
  margin-top: 1%;
  //height: auto;
  img
  {
    width: 90%;
    height: 50%;
  }
}

@media (max-width: 2600px)
{
  .imgWrapper{
    position: relative;
    left: 4%;
    margin-top: 1%;
    //height: auto;
    img
    {
      width: 90%;
      height: 50%;
    }
  }
}

@media (max-width: 1024px) 
{
  .content {
    flex-direction: column-reverse;
    align-items: center;
    background: rgba(251, 204, 63, 0.1);
    .details
    {
      p
      {
        font-family: "Poppins";
        font-size: 20px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
        margin-left: -6%;
        width: 110%;
      }
      span
      {
        //font-family: "Poppins";
        font-size: 18px;
        font-weight: 700;
        line-height: 45px;
        position: relative;
        left: -2%;
      }
    }
  }
  .content
  {
    //flex-direction: column-reverse;
    //align-items: center;
    background: rgba(251, 204, 63, 0.1);
    .detail
    {
      margin-top:-10%;
      p
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
        margin-left: 1%;
        width: 100%;
      }
      li
      {
        list-style-type: disc;
        font-size: 19px;
        font-weight: 700;
        line-height: 45px;
        position: relative;
        left: 1%;
        bottom: 51%;
      }
    }
  }
  h3{
    margin-top:6%;
  }
  .imgWrapper 
  {
    position: relative;
    left: 9.5%;
    min-height: 60%;
    img {
      width: 90%;
      height: auto;
    }
  }
  .contentWrapper {
    h1 
    {
      font-family: "Poppins";
      font-size: 52px;
      font-weight: 700;
      line-height: 68px;
      text-align: center;
      margin-left: 2%;
    }
  }
  .tabsWrapper 
  {
    width: 1000px;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        background-color: #f5f5f5;
        border-radius: 20px;
        padding: 12px;
        max-width: 750px;
        margin: 0 auto;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              background-color: #ffffff;
              border-radius: 20px;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: 0em;
              text-align: center;
              padding: 20px 10px;
              margin-left: 5px;
            }
            &:global(.ant-tabs-tab-active) {
              :global(.ant-tabs-tab-btn) {
                background-color: #fbcc3f;
                color: black;
              }
            }
          }
          :global(.ant-tabs-ink-bar) {
            display: none;
          }
        }
        :global(.ant-tabs-nav-operations) {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) 
{
  .imgWrapper 
  {
    position: relative;
    left: 10%;
    img {
      width: 90%;
      height: auto;
    }
  }
  
  .content
  {
    background: rgba(251, 204, 63, 0.1);
    .details
    {
      p
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
      }
    }
  }
  .content
  {
    //flex-direction: column-reverse;
    //align-items: center;
    background: rgba(251, 204, 63, 0.1);
    .detail
    {
      margin-top:-10%;
      p
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
        margin-left: 1%;
        width: 100%;
      }
      li
      {
        list-style-type: disc;
        font-size: 19px;
        font-weight: 700;
        line-height: 45px;
        position: relative;
        left: 1%;
        bottom: 51%;
      }
    }
  }
  .tabsWrapper 
  {
    width: 500px;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        background-color: #f5f5f5;
        border-radius: 20px;
        padding: 12px;
        //   max-width: 900px;
        margin: 0 -6%;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              background-color: #ffffff;
              border-radius: 20px;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: 0em;
              text-align: center;
              padding: 20px 12px;
              margin-left: 5px;
            }
            &:global(.ant-tabs-tab-active) {
              :global(.ant-tabs-tab-btn) {
                background-color: #fbcc3f;
                color: black;
              }
            }
          }
          :global(.ant-tabs-ink-bar) {
            display: none;
          }
        }
        :global(.ant-tabs-nav-operations) {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 36em) {
  .img1Container {
    background-image: url("../../../public/assets/images/yellow-BG-2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 9rem 0 8rem;
    background-color: transparent;
  }
  .imgBg {
    display: none;
  }
  .imgBgBottom {
    display: none;
  }
  .logo1 {
    bottom: 5rem;
    right: -8px;
    left: unset;
    img {
      width: 171px;
      transform: rotate(200deg);
    }
  }
  .logo2 {
    top: 6rem;
    left: -3%;
    img {
      width: 171px;
      transform: rotate(200deg);
    }
  }
  .contentWrapper {
    h1 {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 6px;
      //margin-left: 2%;
    }
    h4 {
      font-size: 12px;
      line-height: 18px;
      margin: 0 auto 20px;
      max-width: 394px;
    }
  }
  .content {
    background: rgba(251, 204, 63, 0.1);
    .details {
      h4 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        margin-left:-3%;
      }
      p {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
      }
      h3 {
        font-size: 18px;
        line-height: 27px;
        margin-left:-3%;
      }
    }
  }
  .content
  {
    background: rgba(251, 204, 63, 0.1);
    .detail 
    {
      p 
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
        width: 150%;
        margin-left: -25%;
      }
      li
      {
        list-style-type: disc;
        font-size: 18px;
        font-weight: 700;
        line-height: 45px;
        position: relative;
        left: -25%;
        bottom: 51%;
        width: 150%;
      }
    }
  }
  .imgWrapper
  {
    margin-left:-1%;
  } 
        
  .tabsWrapper {
    width: 280px;
    //padding:10px;
    //text-align:center;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        padding: 6px 6px;
        border-radius: 10px;
        max-width: 260px;
        margin: 0 4%;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              font-size: 14px;
              line-height: 21px;
              padding: 8px 15px;
              border-radius: 10px;
              margin-left: 50px;
              text-align:center;
              //margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:540px)
{
  .imgWrapper 
  {
    //max-width:340px;
    max-width:100%;
    
    img
    {
      width: 120%;
      position: relative;
      right: 14%;
    }
  }
  .content
  {
    background: rgba(251, 204, 63, 0.1);
    .details
    {
      p
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: -1px;
        text-align: justify;
        width: 150%;
        margin-left: -22%;
      }
      span
      {
        //font-family: "Poppins";
        font-size: 14px;
        font-weight: 800;
        line-height: 45px;
        position: relative;
        left: -23%;
      }
    }
  }
  .content
  {
    background: rgba(251, 204, 63, 0.1);
    .detail
    {
      p
      {
        font-family: "Poppins";
        font-size: 19px;
        font-weight: 800;
        line-height: 34px;
        word-spacing: -1px;
        text-align: justify;
        width: 150%;
        margin-left: -22%;
      }
      li
      {
        //font-family: "Poppins";
        font-size: 17px;
        font-weight: 700;
        line-height: 45px;
        position: relative;
        left: -23%;
        width: 150%;
      }
    }
  }
}

@media only screen and (min-width:640px), (max-width:768px)
{
  .content
  {
    background: rgba(251, 204, 63, 0.1);
  }
  .tabsWrapper 
  {
    max-width:100%;
  }
  
  @media only screen and (max-width:912px)
  {
    .tabsWrapper
    {
      max-width: 65%;
    }
  }
}
