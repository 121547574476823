.wrapper {
  margin: 5rem auto;
  position: relative;
}
.img1Container {
  // background-image: url("../../../public/assets/images/teams-bg.png");
  // background-repeat: no-repeat;
  // padding: 12rem 0;
  // background-size: 100% 100%;

  background-color: #ffc410;
  padding: 12rem 0 18rem;
}
.imgBg {
  width: 100%;
  position: absolute;
  top: -1px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.imgBgBottom {
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
  img {
    width: 100%;
  }
}
.logo1 {
  position: absolute;
  bottom: 8rem;
  left: -15px;
  img {
    height: 240px;
  }
}
.logo2 {
  position: absolute;
  top: 8rem;
  right: -15px;
  img {
    height: 240px;
  }
}
.logo3 {
  position: absolute;
  bottom: 8rem;
  right: 0;
  img {
    height: 400px;
  }
}
.logo4 {
  position: absolute;
  top: -5px;
  left: 1px;
  img {
    width: 100%;
    height: 500px;
  }
}
.contentWrapper {
  max-width: 1280px;
  margin: 0 auto;
  h1 {
    font-family: "Poppins";
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 20px;
  }
  p {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 600;
    line-height: 34px;
    word-spacing:5px;
    letter-spacing: 0em;
    text-align: justify;
    max-width: 982px;
    margin: 0 auto 30px;
  }
}

.teamsSlider {
  :global(.slick-prev) {
    z-index: 9999;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
      margin-left: 15px; //-30px;
      margin-top: -102%;
    }
  }
  :global(.slick-next) {
    z-index: 9999;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn-right.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
      margin-top: -102%;
      margin-left: -35px;
    }
  }
  :global(.slick-dots) {
    bottom: -50px;
    button {
      width: 16px;
      height: 16px;
      &::before {
        outline: 1px solid black;
        outline-offset: -2px;
        border-radius: 50px;
        top: -2px;
        font-size: 14px;
        line-height: 22px;
        color: transparent;
        opacity: 1;
      }
    }
  }
  :global(.slick-active) {
    button {
      &::before {
        color: black;
      }
    }
  }
}
.content {
  max-width: 610px;
  .imgWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    img {
      width: 250px;
      height: 250px;
      border-radius: 20px;
      margin-bottom: 50px;
    }
    position: relative;
  }
  .details {
    background: white;
    box-shadow: 0px 0px 20px rgba(47, 50, 125, 0.2),
      inset 0px 0px 4px rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(2.71828px);
    border-radius: 20px;
    width: 280px;
    padding: 4px;
    position: absolute;
    bottom: 0;
    //margin-top: 15%;
    margin-bottom: 10px;
    h4 {
      font-family: "Poppins";
      font-size: 24px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;
    }
    p {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: center;
      margin: 0;
    }
  }
}

@media (max-width: 1024px) 
{
  .contentWrapper 
  {
    max-width: 750px;
    margin: 0 auto;
  }

  .content 
  {
    max-width: 310px;
    .imgWrapper 
    {
      margin-left: 5rem;
      img {
        width: 105%;
        height: auto;
      }
    }
    
  }
  .teamsSlider 
  {
    .imgWrapper
    {
      //margin-left: 25%;
      position: relative;
      left:-15%;
      //min-width:63%;
    }
    :global(.slick-prev) {
      &::before {
        position: relative;
        left: 2px;
      }
    }
    :global(.slick-next) {
      &::before {
        position: relative;
        right: 1px;
      }
    }
  }
    
}
@media (min-width:820px),(max-width:912px)
{
  .content
  {
    .details
    {
      width: 240px;
      p
      {
        font-family: "Poppins";
        font-size: 15px;
        font-weight: 600;
        line-height: 27px;
        letter-spacing: 0em;
        text-align: center;
        margin: 0;
      }
    }
  }
}

@media (max-width: 768px) 
{
  .contentWrapper {
    margin: 0 auto;
    max-width: 650px;
  }
  .teamsSlider 
  {
    .imgWrapper
    {
      //margin-left: 2%;
      position: relative;
      left:8%; //-0.5;
      //width: 80%;
    }
    :global(.slick-prev) {
      &::before {
        position: relative;
        left: -40px;
      }
    }
    :global(.slick-next) {
      &::before {
        position: relative;
        right: -40px;
      }
    }
  }
  .content 
  {
    .imgWrapper {
      margin-left: -10%;
      img {
        width: 80%;
        height: auto;
      }
    }
  }
}

@media (max-width: 36em) {
  .img1Container {
    background-image: url("../../../public/assets/images/yellow-BG-2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 7rem 0 10rem;
    background-color: transparent;
  }
  .imgBg {
    display: none;
  }
  .imgBgBottom {
    display: none;
  }
  .logo1 {
    position: absolute;
    bottom: 6rem;
    left: 0px;
    img {
      height: 101px;
    }
  }
  .logo2 {
    position: absolute;
    top: 5rem;
    right: -45px;
    img {
      height: 107px;
    }
  }
  .logo3 {
    position: absolute;
    bottom: 8rem;
    right: 0;
    img {
      height: 175px;
    }
  }
  .logo4 {
    position: absolute;
    top: 0px;
    left: -15px;
    img {
      width: 100%;
      height: 290px;
    }
  }
  .contentWrapper {
    margin: 0 auto;
    max-width: 650px;
    padding: 0 1.25rem;
    h1 {
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      text-align: center;
      margin-bottom: 6px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: justify;
      max-width: 90%;
      margin: 0 auto 20px;
      margin-left: 6%;
    }
  }
  .sliderWrapper {
    max-width: 274px;
    margin: 0 auto;
  }
  .teamsSlider {
    :global(.slick-prev) {
      &::before {
        position: relative;
        left: -40px;
      }
    }
    :global(.slick-next) {
      &::before {
        position: relative;
        right: -40px;
      }
    }
    :global(.slick-dots) {
      button {
        &::before {
          outline-offset: -3px;
          font-size: 10px;
        }
      }
    }
  }
  .content {
    max-width: 300%;
    display: grid !important;
    place-content: center;
    .imgWrapper {
      margin-left: -15%;
      img {
        width: 250px;
        height: auto;
      }
    }
    .details {
      width: 264px;
      height: 8%;
      border-radius: 10px;
      h4 {
        font-size: 10px;
        line-height: 15px;
      }
      p {
        font-size: 15px;
        line-height: 12px;
        margin-left: 10px;
      }
    }
  }
}
/*
@media only screen and (min-width:720px), (max-width:750px)
{
  .teamsSlider
  {
    :global(.slick-next) {
      &::before {
        position: relative;
        right: -50%;
      }
    }
  }
}
*/

@media only screen and (min-width:912px)
{
  .details
  {
    max-width:140%;
  }
  .teamsSlider
  {
    :global(.slick-next) {
      &::before {
        position: relative;
        right: -5%;
      }
    }
  }
  .contentWrapper
  {
    h1
    {
      position: relative;
      left: 10px; 
    }
  }
}
