.container 
{
  max-width: 94%;
  min-height: 200px; // min-height: 200px
  padding: 1.125rem 2rem;
  margin: auto auto;
  display: flex;
  //justify-content: space-between;
}
.header 
{
  font-family: "Poppins";
  font-size: 27px;
  font-weight: 800;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 3%;
  width: 100%;
  position: relative;
  left: 5%;
}
.u2img 
{
  height: 90px;
  min-width: 400px;
  margin-left: 10%;
  position: relative;
  top: 15px;
  //margin-top: 32%;
}
h1 
{
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2%;
  //display: block;
  //margin-left:-1%;
  min-width: 300px;
}
.para 
{
  font-size: 25px;
  font-weight: 800;
  position: relative;
  top: 60%;
  left: 200px;
  font-family: 'Poppins', sans-serif;
}
span 
{
  img 
  {
    position: relative;
    margin-left: 1080px;
    max-height: 60%;
    max-width: 60%;
    bottom: 110px;
  }
}
.headerlogo 
{
  position: relative;
  margin-left: 450px;
  max-height: 100%;
  max-width: 100%;
  top: 10px;
  left: 145%;
  //background-color: #c4aead;
}
.logo 
{
  color: green;
  margin: auto 0;
}
.menuOutlined 
{
  display: none;
  margin: auto 0;
  svg 
  {
    width: 1.5rem;
    height: 1.5rem;
  }
}
.navLink 
{
  text-decoration: none;
  font-size: 20px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin-left: 2.5%; //2rem;
  position: relative;
  left: 590px;
  top: 7%;
}
.langMenu 
{
  font-size: 16px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin: 0 2rem;
}
.login 
{
  :global(.ant-btn) 
  {
    width: 110px;
    height: 47px;
    justify-content: center;
    align-items: center;
    padding: 5px 13px;
    background: #ffc410;
    border: 3px solid #ffe698;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  :global(.ant-btn:hover) 
  {
    background: white;
    color: #14113a;
    border: 3px solid #14113a;
  }
}
.mobileNavLink 
{
  display: none;
}

@media (min-width: 1600px) and (max-width: 2400px)
{
  .u2img 
  {
    position: relative;
    left: -15%;
  }
  .navLink 
  {
    display: inline;
    text-decoration: none;
    font-size: 20px;
    font-family: "poppins";
    font-weight: 700;
    color: #14113a;
    //margin-right: 120px; //2rem;
    position: relative;
    left: 550px;
    top: 7%;
    width: 100%;
  }
  .headerlogo 
  {
    position: relative;
    left: 100px;
    //margin-left: 390px;
    max-height: 100%;
    max-width: 100%;
    top: 10px;
    //background-color: #c4aead;
  }
}

@media (min-width: 1000px) and (max-width: 1300px) 
{
  .container 
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: -2%;
  }
  .u2img 
  {
    position: relative;
    left: -1%;
  }
  // .desktopNavLink 
  // {
  //   //display: block;
  //   display: flex;
  //   flex-direction: row-reverse;
  //   padding: 1rem 0;
  //   margin-left: -50%;
  //   width: 80%;
  // }
  .menuOutlined 
  {
    display: none;
    // margin-right: 18%;
    // margin-bottom: 18%;
    // position: relative;
    // bottom: 8px;
  }
  .mobileNavLink 
  {
    display: none;
    // display: flex;
    // flex-direction: column;
    // padding: 0 1rem;
    // width: 94%;
    // margin: 0 auto;
  }
  .navLink 
  {
    display: inline;
    text-decoration: none;
    font-size: 20px;
    font-family: "poppins";
    font-weight: 700;
    color: #14113a;
    //margin-right: 120px; //2rem;
    position: relative;
    left: 85%;
    top: 7%;
    width: 100%;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 480px;
    max-height: 100%;
    max-width: 100%;
    top: 10px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }
  .langMenu 
  {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (max-width: 820px), (max-width: 912px) 
{
  .container 
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: 27%;
  }
  .u2img 
  {
    position: relative;
    left: 20%;
  }
  .desktopNavLink 
  {
    display: none;
  }
  .menuOutlined 
  {
    display: block;
    margin-left: 16%;
    margin-bottom: 12%;
    position: relative;
    bottom: 2px;
  }
  .mobileNavLink 
  {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }
  .navLink 
  {
    padding: 1rem 0;
    margin-left: -250px;
    position: relative;
    bottom: 90px;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 150px;
    max-height: 100%;
    max-width: 100%;
    top: -85px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }
  .langMenu 
  {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (min-width: 650px) and (max-width: 768px) 
{
  .container 
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: 27%;
  }
  .u2img 
  {
    position: relative;
    left: 20%;
  }
  .desktopNavLink 
  {
    display: none;
  }
  .menuOutlined 
  {
    display: block;
    margin-left: 17%;
    margin-bottom: 18%;
    position: relative;
    bottom: 1px;
  }
  .mobileNavLink 
  {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }
  .navLink 
  {
    padding: 1rem 0;
    margin-left: -250px;
    position: relative;
    bottom: 90px;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 160px;
    max-height: 100%;
    max-width: 100%;
    top: -85px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }

  .langMenu {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (min-width: 500px) and (max-width: 580px) 
{
  .container 
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: 7%;
  }
  .u2img 
  {
    position: relative;
    left: -12%;
  }
  .desktopNavLink 
  {
    display: none;
  }
  .menuOutlined 
  {
    display: block;
    margin-right: 2%;
    margin-bottom: 18%;
    position: relative;
    bottom: -10px;
  }
  .mobileNavLink 
  {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }
  .navLink 
  {
    padding: 1rem 0;
    margin-left: -380px;
    position: relative;
    bottom: 60px;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 390px;
    max-height: 100%;
    max-width: 100%;
    top: -55px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }
  .langMenu 
  {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (min-width: 370px) and (max-width: 480px) 
{
  .container 
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: 7%;
  }
  .u2img 
  {
    position: relative;
    left: -13%;
    min-width: 300px;
  }
  .desktopNavLink 
  {
    display: none;
  }
  .menuOutlined 
  {
    display: block;
    margin-right: 1%;
    margin-bottom: 18%;
    position: relative;
    bottom: -18px;
  }
  .mobileNavLink 
  {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }
  .navLink 
  {
    padding: 1rem 0;
    margin-left: -380px;
    position: relative;
    bottom: 60px;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 390px;
    max-height: 100%;
    max-width: 100%;
    top: -55px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }
  .langMenu 
  {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (max-width: 360px) 
{
  .container
  {
    padding: 1.125rem 1rem;
  }
  .header 
  {
    font-family: "Poppins";
    font-size: 30px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-top: 3%;
    width: 100%;
    position: relative;
    left: 1%;
  }
  .u2img 
  {
    position: relative;
    left: -16%;
    min-width: 300px;
  }
  .desktopNavLink 
  {
    display: none;
  }
  .menuOutlined 
  {
    display: block;
    margin-left: -4%;
    margin-bottom: 18%;
    position: relative;
    bottom: -21px;
  }
  .mobileNavLink 
  {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }
  .navLink 
  {
    padding: 1rem 0;
    margin-left: -410px;
    position: relative;
    bottom: 60px;
  }
  .headerlogo 
  {
    position: relative;
    margin-left: 350px;
    max-height: 100%;
    max-width: 100%;
    top: -55px;
    //background-color: #c4aead;
  }
  .login 
  {
    padding-top: 0.5rem;
    :global(.ant-btn) 
    {
      padding: 8px;
      margin: 0;
    }
  }
  .langMenu 
  {
    margin: 0;
    padding: 1rem 0;
  }
}

@media only screen and (max-width:360px) {
  .container {
    h1 {
      //color:darkcyan;
      margin-left: -10px;
      font-weight: 900px;
      font-size: 25px;
    }
  }
}


@media only screen and (min-width:768px),
(max-width:912px) {
  .container {
    h1 {
      //color:darkcyan;
      margin-left: 40px;
      font-weight: 1000;
      font-size: 28px;
      width: 180%;
    }
  }

  .desktopNavLink {
    margin-right: 100px;
    //display:none;
  }

  .menuOutlined {
    margin-top: 5%;
    position: relative;
    left: -14px;
  }

  span {
    img {
      position: relative;
      left: -8px;
    }
  }
}

@media only screen and (max-width: 540px) {
  .container {
    h1 {
      //color:darkcyan;
      //margin-left: -10px;
      font-weight: 900px;
      font-size: 35px;
      position: relative;
      top: 7px;
    }

    .desktopNavLink {
      background-color: yellow;
      margin-right: -250px;
    }
  }

  .mobileNavLink {
    span {
      img {
        position: relative;
        left: -250px;
        top: -50px;
      }
    }

    .desktopNavLink {
      background-color: yellow;
      margin-right: -150px;
    }
  }
}

@media only screen and (min-width:1000px),
(max-width: 1200px) {
  .desktopNavLink {
    position: relative;
    right: 350px;
    background: 'red';
  }

  .para {
    position: relative;
    left: 250px;
  }
}

/*@media only screen and (min-width:1200px), (max-width:1600px)
{
  .container
    {
      h1
        {
          color:darkcyan;
          margin-left: -25px;
          font-weight:1000;
          font-size:25px;
          width: 180%;
        }
    }
    .navLink
    {
      font-size: 20px;
    }
  .desktopNavLink
    {
      margin-right:230px;
      margin-top: 20px;
    }
    span
    {
      img
      {
        position:relative;
        left:458px;
        //float:left;
        //min-width:100px;
        //height:auto;
      }
    }
} */