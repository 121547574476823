.wrapper 
{
  max-width: 1600px;
  max-height: 50%;
  //margin: 0 auto;
  position: relative;
  top: 100px;
}
.container 
{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.contentWrapper 
{
  max-width: 634px;
  position: relative;
  //max-height: 50%;
  h4 
  {
    font-family: "Georgia";
    font-size: 38px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative;
    left: 10%;
  }
  h2 
  {
    font-family: "Poppins";
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
    color: red;
  }
  p 
  {
    font-family: "Poppins"; //fangsong
    font-size: 35px;
    font-weight: 700;
    line-height: 34px;
    word-spacing: 5px;
    text-align: justify;
    position: relative;
    left: 10%;
    width: 600px;
  }
}
.btn
{
  font-family: "Poppins";
  padding: 14px 38px;
  display: inline-block;
  background: #ffc410;
  cursor: pointer;
  border: 3px solid #ffe698;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.imgWrapper 
{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  margin-top: 5%;
  img 
  {
    min-width: 80%;
    height: auto;
    position: relative;
    left: 5%;
  }
}

@media (max-width: 2600px)
{
  .imgWrapper 
  {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 80%;
    margin-top: 5%;
    img 
    {
      width: 90%;
      height: auto;
    }
  }
}

@media (min-width: 1700px) and (max-width: 2400px)
{
  .wrapper
  {
    position: relative;
    right: 22.5%;
  }
  .imgWrapper 
  {
    max-width: 650px;
  }
}

@media (min-width: 1500px) and (max-width: 1600px)
{
  .wrapper
  {
    position: relative;
    right: 11%;
  }
  .imgWrapper 
  {
    max-width: 600px;
  }
}

@media (max-width: 1024px) 
{
  .container 
  {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .contentWrapper
  {
    p
    {
      font-family: "Poppins";
      font-size: 17px;
      font-weight: 800;
      line-height: 34px;
      word-spacing: 5px;
      text-align: justify;
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }
  .imgWrapper 
  {
    max-width: 80%;
    margin-top: -20px;
  }
}

@media (min-width: 768px) and (max-width: 912px)
{
  .container 
  {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .contentWrapper
  {
    h4 
    {
      font-family: "Georgia";
      font-size: 38px;
      font-weight: 800;
      line-height: 27px;
      letter-spacing: 0em;
      margin-bottom: 20px;
      margin-top: -10%;
      margin-left: 20px;
      position: relative;
      left: -5%;
    }
    p
    {
      font-family: "Poppins";
      font-size: 19px;
      font-weight: 700;
      line-height: 34px;
      word-spacing: 3px;
      text-align: justify;
      position: relative;
      left: -5%;
      top: -50%;
    }
  }
  .imgWrapper
  {
    max-width: 80%;
    margin-top: 1px;
    width: 600px;
    img
    {
      min-height: 300px;
      min-width: 600px;
      position: relative;
      top: -35px;
      left: -5%;
    }
  }
}

@media (min-width: 500px) and (max-width: 540px)
{
  .wrapper 
  {
    height: 750px;
    margin: 0 auto;
  }
  .contentWrapper
  {
    h4 
    {
      font-family: "Georgia";
      font-size: 38px;
      font-weight: 800;
      line-height: 27px;
      letter-spacing: 0em;
      margin-bottom: 20px;
      margin-left: 20px;
      position: relative;
      left: -1%;
    }
    p
    {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 700;
      line-height: 34px;
      text-align: justify;
      position: relative;
      left: -1%;
      width: 100%;
    }
  }
  .imgWrapper 
  {
    margin-top: 1px;
    img
    {
      min-width: 380px;
      height: 250px;
      position: relative;
      top: -43%;
      left: 1%;
    }
  }
} 

@media (min-width: 370px) and (max-width: 480px)
{
  .wrapper 
  {
    height: 750px;
    margin: 0 auto;
  }
  .contentWrapper
  {
    h4 
    {
      font-family: "Georgia";
      font-size: 38px;
      font-weight: 800;
      line-height: 27px;
      letter-spacing: 0em;
      margin-bottom: 20px;
      margin-left: 20px;
      position: relative;
      left: -1%;
    }
    p
    {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 700;
      line-height: 34px;
      text-align: justify;
      position: relative;
      left: -1%;
      width: 100%;
    }
  }
  .imgWrapper 
  {
    margin-top: 1px;
    img
    {
      min-width: 220px;
      height: 200px;
      position: relative;
      top: -43%;
      left: -1%;
    }
  }
} 

@media (max-width: 360px)
{
  .wrapper
  {
    height: 100%;
    position: relative;
    top: -70px;
    //margin-top: 50%;
  }
  .imgWrapper
  {
    margin-top: 1px;
    height: 300px;
    width: 300px;
    img
    {
      width: 200px;
      min-height: 150px;
      position: relative;
      left: 1%;
      top: -29%;
    }
  }
  .contentWrapper
  {
  h4 
  {
    font-family: "Georgia";
    font-size: 38px;
    font-weight: 800;
    line-height: 27px;
    letter-spacing: 0em;
    margin-bottom: 20px;
    margin-left: 20px;
    position: relative;
    left: -1%;
  }
  p
    {
      font-family: "Poppins";
      font-size: 12px;
      font-weight: 700;
      line-height: 34px;
      //word-spacing: 3px;
      text-align: justify;
      position: relative;
      left: 1%;
      width: auto;
    }
  }
}

@media (max-width: 36em) 
{
  .wrapper 
  {
    height: 1100px;
    margin: 0 auto;
  }
  .container 
  {
    padding: 0 1.25rem;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .imgWrapper 
  {
    max-width: 90%;
    height: 750px;
    img
    {
      position: relative;
      bottom: 255px;
      max-height: 35%;
      max-width: 300px;
    }
  }
  .contentWrapper 
  {
    h4 
    {
      font-size: 28px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    h2 
    {
      font-size: 24px;
      margin-bottom: 6px;
      line-height: 42px;
    }
    p 
    {
      font-size: 19px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width:360px)
{
  .wrapper 
  {
    min-height: 950px;
    margin: 0 auto;
  }
  .imgWrapper 
  {
    margin-top: 0px;
    img
    {
      height: 300px;
      bottom: 275px;
      position: relative;
    }
  }
}

@media only screen and (min-width: 375px), (max-width:480px)
{
  .contentWrapper 
  {
    h4
    {
      font-size: 20px;
      font-weight: 900;
      margin-left: 1px;
    }
    p 
    {
      font-size: 14px;
      margin-bottom: 20px;
      margin-left: 1px;
    }
  }
  .wrapper 
  {
    min-height: 900px;
    margin: 0 auto;
  }
  
}

@media only screen and (min-width:500px), (max-width:600px)
{
  .wrapper 
  {
    height: 750px;
    margin: 0 auto;
  }
  .imgWrapper 
  {
    margin-top: 45px;
    img
    {
      width: 400px;
      max-height: 350px;
    }
  }
}

@media only screen and (max-width:912px)
{
  .contentWrapper 
  {
    h4
    {
      font-size: 20px;
      font-weight: 900;
      margin-left:1px;
    }
    p 
    {
      font-size: 14px;
      margin-bottom: 20px;
      margin-left: 1px;
    }
  }
}

