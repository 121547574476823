.whatWeDoContainer 
{
    width:100%;
    margin: 8rem auto;
    position: relative;
    top: 80px;
    h2 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        line-height: 2.375rem;
        text-align: center;
        color: #333333;
        margin-bottom: 1.25rem;
    }
    h3 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 2.375rem;
        text-align: left;
        color: #333333;
        margin-bottom: 1.25rem;
        margin-left: 1%;
    }
    .footerList 
    {
        margin-left: 10%;
        margin-right: 2%;
        margin-top: -10px;
        padding-top: 1px;
       li 
       {
          font-family: Poppins, sans-seri; //fGraphik Light
          margin-bottom: 14px;
        //list-style-type: circle;
          color: black;
          cursor: pointer;
          font-weight: 600;
          font-size: 20px;
          text-align:justify;
        }
    }
    .subTitle 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        word-spacing: 3px;
        text-align: justify;
        color: #333333;
        width: 70%;
        margin: 0 auto 0.5rem;
    }
    :global(.ant-row) 
    {
        justify-content: center;
    }
    :global(.ant-col) 
    {
        background-color: #ffc410cc;
        border: 2px solid;
        border-color: #ffc410;
        border-radius: 20px;
        min-height: 25.0625rem; //30.0625rem;
        min-width: 20.75rem;
        // position: relative;
        margin: 1rem 0;
        h2 
        {
            color: #ffc410; //#333;
            font-family: "Poppins", Helvetica;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            margin-top: 1.5rem;
            padding: 0 0.5rem;
            background: #333;
            height: 30%;
            width: 101%;
            position: relative;
            bottom:6.4%;
            left: -0.5%;
            border-radius: 18px;
            padding: 5%;
        }
        p 
        {
            color: #333;
            font-family: "Poppins", Helvetica;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            padding: 0 2rem;
            text-align: center;
        }
        :global(.ant-btn) 
        {
            backdrop-filter: blur(5.44px) brightness();
            background-color: #333;
            height: 9%;
            width: 62%;
            border-radius: 4px;
            margin: 2rem auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fbcc3f;
            box-shadow: 0 0 20px #ffffff1a, inset 0 0 4px #ffffff4c;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            // position: absolute;
            // bottom: 0;
            // left: 4.0625rem;
        }
        :global(.ant-btn:hover) 
        {
            background-color: white;
            color: #14113a;
        }
    }
    :global(.ant-col:nth-child(2)) 
    {
        margin-left: 3rem;
        margin-right: 3rem;
    }
    .millettxt
    {
        position: relative;
        left: 30%;
        top: 5%;
        font-size: 17px;
        font-weight: 500;
    }
    .milletbtn
    {
        position: relative;
        left: 58%;
        bottom: 4px;
        color: #FFF;
        font-size: 15px;
        background-color: #1E90FF;
    }
}

@media (min-width: 1500px) and (max-width: 1600px)
{
    .whatWeDoContainer
    {
        min-height: 800px;
        h2 
        {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 2.375rem;
            text-align: center;
            color: #333333;
            margin-right: -5%;
        }
        .millettxt
        {
            position: relative;
            left: 25%;
            //margin-left: -5%;
            top: 2.3%;
            font-size: 17px;
            font-weight: 500;
        }
        .milletbtn
        {
            position: relative;
            left: 47.5%;
            bottom: 26px;
            color: #FFF;
            font-size: 15px;
            background-color: #1E90FF;
        }
    }
    .cardContainer
    {
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            //min-height: 25.0625rem; //30.0625rem;
            max-width: 380px;
            position: relative;
            right: 9%;
            margin: 1rem 0;
        } 
    }
    .subTitle 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        word-spacing: 3px;
        text-align: justify;
        color: #333333;
        max-width: 900px;
        margin: 0 auto 0.5rem;
        position: relative;
        right: 8%;
    }
}

@media (min-width: 1700px) and (max-width: 2400px)
{
    .whatWeDoContainer
    {
        h2 
        {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 2.375rem;
            text-align: center;
            color: #333333;
            //margin-bottom: 1.25rem;
            position: relative;
            margin-right: 28%;
        }
        .millettxt
        {
            position: relative;
            left: 17%;
            //margin-left: -5%;
            top: 5%;
            font-size: 17px;
            font-weight: 500;
        }
        .milletbtn
        {
            position: relative;
            left: 33%;
            bottom: 4px;
            color: #FFF;
            font-size: 15px;
            background-color: #1E90FF;
        }
    }
    .cardContainer
    {
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            //min-height: 25.0625rem; //30.0625rem;
            max-width: 380px;
            position: relative;
            right: 22%;
            margin: 1rem 0;
        } 
    }
    .subTitle 
    {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 34px;
        word-spacing: 3px;
        text-align: justify;
        color: #333333;
        max-width: 900px;
        margin: 0 auto 0.5rem;
        position: relative;
        right: 23%;
    }
}

@media (max-width: 1024px) 
{
    .whatWeDoContainer 
    {
        width: 91%;
        .cardContainer
        {
            margin-left: 20px;
        }
        .subTitle 
        {
            width: 85%;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 800;
            line-height: 34px;
            word-spacing: 5px;
            text-align: justify;
        }
        h2 
        {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 3.375rem;
            text-align: center;
            color: #333333;
            margin-bottom: 1.25rem;
        }
    }
}

@media (min-width: 768px) and (max-width: 912px)
{
    .whatWeDoContainer
    {
        width: 106%;
        //margin-right: -6.5%;
        h2 
        {
            font-family: "Poppins";
            font-style: normal;
            font-weight: 700;
            font-size: 52px;
            line-height: 3.375rem;
            text-align: center;
            color: #333333;
            margin-bottom: 1.25rem;
        }
        .subTitle 
        {
            width: 85%;
            margin-left:7%;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 800;
            line-height: 34px;
            word-spacing: 5px;
            text-align: justify;
        }
        .millettxt
        {
            font-size: 25px;
            font-weight: 700px;
            position: relative;
            left: 95px;
            top: 2px;
        }
        .milletbtn
        {
            position: relative;
            left: 180px;
            top: 15px;
            width: 45%;
        }
    }
}

@media (min-width: 500px) and (max-width: 600px) 
{
    .whatWeDoContainer
    {
        width: auto;
        .subTitle 
        {
            width: 80%;
            margin-left: 8%;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 800;
            line-height: 34px;
            word-spacing: 5px;
            text-align: justify;
        }
        h2
        {
            line-height:100%;
            font-size: 34px;
            margin-left: -2%;
        }
        .cardContainer
        {
            margin-left: 5px;
        }
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            min-height: 25.0625rem; //30.0625rem;
            min-width: 20.75rem;
            // position: relative;
            margin: 1rem 0;
            h2 
            {
                color: #ffc410; //#333;
                font-family: "Poppins", Helvetica;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                margin-top: 1.5rem;
                padding: 0 0.5rem;
                background: #333;
                height: 30%;
                width: 101%;
                position: relative;
                bottom:6.4%;
                left: 1.8%;
                border-radius: 18px;
                padding: 5%;
            }
        }
        .millettxt
        {
            font-size: 20px;
            font-weight: 700px;
            position: relative;
            left: 45px;
            top: 2px;
        }
        .milletbtn
        {
            position: relative;
            left: 120px;
            top: 15px;
            width: 45%;
        }
    }
}

@media (min-width: 370px) and (max-width: 480px) 
{
    .whatWeDoContainer
    {
        width: auto;
        .subTitle 
        {
            width: 80%;
            margin-left: 8%;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 800;
            line-height: 34px;
            word-spacing: 5px;
            text-align: justify;
        }
        h2
        {
            line-height:100%;
            font-size: 30px;
            margin-left: -2%;
        }
        .cardContainer
        {
            margin-left: 5px;
        }
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            min-height: 25.0625rem; //30.0625rem;
            min-width: 20.75rem;
            // position: relative;
            margin: 1rem 0;
            h2 
            {
                color: #ffc410; //#333;
                font-family: "Poppins", Helvetica;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                margin-top: 1.5rem;
                padding: 0 0.5rem;
                background: #333;
                height: 30%;
                width: 101%;
                position: relative;
                bottom:6.4%;
                left: 1.8%;
                border-radius: 18px;
                padding: 5%;
            }
        }
        .millettxt
        {
            font-size: 18px;
            font-weight: 700px;
            position: relative;
            left: 20px;
            top: 2px;
        }
        .milletbtn
        {
            position: relative;
            left: 120px;
            top: 15px;
            width: 40%;
        }
    }
}

@media (max-width: 360px) 
{
    .whatWeDoContainer
    {
        width: auto;
        .subTitle 
        {
            width: 80%;
            margin-left: 8%;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 800;
            line-height: 34px;
            word-spacing: 5px;
            text-align: justify;
        }
        h2
        {
            line-height:100%;
            font-size: 30px;
            margin-left: -2%;
        }
        .cardContainer
        {
            margin-left: 5px;
        }
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            min-height: 25.0625rem; //30.0625rem;
            min-width: 20.75rem;
            // position: relative;
            margin: 1rem 0;
            h2 
            {
                color: #ffc410; //#333;
                font-family: "Poppins", Helvetica;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                margin-top: 1.5rem;
                padding: 0 0.5rem;
                background: #333;
                height: 30%;
                width: 101%;
                position: relative;
                bottom:6.4%;
                left: 1.8%;
                border-radius: 18px;
                padding: 5%;
            }
        }
        .millettxt
        {
            font-size: 16px;
            font-weight: 700px;
            position: relative;
            left: 40px;
            top: 2px;
        }
        .milletbtn
        {
            position: relative;
            left: 140px;
            top: 15px;
        }
    }
}

@media only screen and (max-width:912px)
{
    .whatWeDoContainer
    {
        width: auto;
        position: relative;
        left: 0.5%;
    }
    
}
 @media only screen and (max-width:540px)
 {
    .whatWeDoContainer
    {
        :global(.ant-col) 
        {
            background-color: #ffc410cc;
            border: 2px solid;
            border-color: #ffc410;
            border-radius: 20px;
            min-height: 25.0625rem; //30.0625rem;
            min-width: 20.75rem;
            // position: relative;
            margin: 1rem 0;
            h2 {
                color: #ffc410; //#333;
                font-family: "Poppins", Helvetica;
                font-size: 2rem;
                font-style: normal;
                font-weight: 700;
                margin-top: 1.5rem;
                padding: 0 0.5rem;
                background: #333;
                height: 30%;
                width: 101%;
                position: relative;
                bottom:6.4%;
                left: 1.7%;
                border-radius: 18px;
                padding: 5%;
            }
        }
    }
}