.wrapper {
  margin: 5rem 0px;
  position: relative;
}
.imgBg {
  width: 100%;
  position: absolute;
  top: -1px;
  overflow: hidden;
  img {
    width: 100%;
  }
}
.imgBgBottom {
  width: 100%;
  overflow: hidden;
  position: absolute;
  bottom: -1px;
  img {
    width: 100%;
  }
}
.logo1 {
  position: absolute;
  bottom: 8rem;
  left: -15px;
  img {
    width: 350px;
  }
}
.logo2 {
  position: absolute;
  top: 5rem;
  right: -15px;
  img {
    width: 350px;
  }
}
.img1Container {
  background-color: #ffc410;
  padding: 12rem 0;
}
.contentWrapper {
  max-width: 1160px;
  margin: 0 auto;
  margin-bottom: 20px;
  padding: 0 1.25rem;
  h1 {
    font-family: "Poppins";
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    text-align: center;
  }
  h4 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 758px;
    margin: 0 auto 20px;
    
  }
}
.teamsSlider 
{
  :global(.slick-prev) {
    //z-index: auto;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: auto;
      opacity: 1;
      display: block;
      position: relative;
      left:900%;
      //margin-left: 500%; //-30px;
      //margin-top: 0;
    }
  }
  :global(.slick-next) {
    z-index: 9999;
    &::before {
      background-image: url("../../../public/assets/images/previousBtn-right.svg");
      background-color: #000000;
      border-radius: 50%;
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
      margin-top: -252%;
      margin-left: -35px;
    }
  }
}
.tabsWrapper {
  max-width:1160px;
  margin: 0 auto;
  :global(.ant-tabs) {
    :global(.ant-tabs-nav) {
      background-color: #f5f5f5;
      border-radius: 20px;
      padding: 12px;
      //   max-width: 900px;
      //   margin: 0 auto;
      &::before {
        display: none;
      }
      :global(.ant-tabs-nav-wrap) {
        :global(.ant-tabs-tab) {
          :global(.ant-tabs-tab-btn) {
            background-color: #ffffff;
            border-radius: 20px;
            font-family: "Poppins";
            font-size: 20px;
            font-weight: 600;
            line-height: 36px;
            letter-spacing: 0em;
            text-align: center;
            padding: 20px 18px;
          }
          &:global(.ant-tabs-tab-active) {
            :global(.ant-tabs-tab-btn) {
              background-color: #fbcc3f;
              color: black;
            }
          }
        }
        :global(.ant-tabs-ink-bar) {
          display: none;
        }
      }
      :global(.ant-tabs-nav-operations) {
        display: none;
      }
    }
  }
}
.content {
  display: flex;
  justify-content: center;
  background: rgba(251, 204, 63, 0.3);
  border-radius: 20px;
  margin: 40px 10px;
  .details {
    max-width: 521px;

    h4 {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: -30%;
    }
    p {
      font-family: "Poppins";
      font-size: 18px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 20px;
    }
    h3 {
      font-family: "Poppins";
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      text-align: left;
      margin-left: -30%;
    }
   
  }
}

@media (max-width: 1024px) 
{
  .content {
    flex-direction: column-reverse;
    align-items: center;
  }
  h3{
    margin-top:6%;
  }
  .imgWrapper {
    img {
      width: 100%;
      height: auto;
    }
  }
  .tabsWrapper 
  {
    max-width: 950px;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        background-color: #f5f5f5;
        border-radius: 20px;
        padding: 12px;
        //   max-width: 900px;
        //   margin: 0 auto;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              background-color: #ffffff;
              border-radius: 20px;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: 0em;
              text-align: center;
              padding: 20px 10px;
              margin-left: 5px;
            }
            &:global(.ant-tabs-tab-active) {
              :global(.ant-tabs-tab-btn) {
                background-color: #fbcc3f;
                color: black;
              }
            }
          }
          :global(.ant-tabs-ink-bar) {
            display: none;
          }
        }
        :global(.ant-tabs-nav-operations) {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 768px) 
{
  .tabsWrapper 
  {
    width: 600px;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        background-color: #f5f5f5;
        border-radius: 20px;
        padding: 12px;
        //   max-width: 900px;
        //   margin: 0 auto;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              background-color: #ffffff;
              border-radius: 20px;
              font-family: "Poppins";
              font-size: 20px;
              font-weight: 600;
              line-height: 36px;
              letter-spacing: 0em;
              text-align: center;
              padding: 20px 18px;
              margin-left: 20px;
            }
            &:global(.ant-tabs-tab-active) {
              :global(.ant-tabs-tab-btn) {
                background-color: #fbcc3f;
                color: black;
              }
            }
          }
          :global(.ant-tabs-ink-bar) {
            display: none;
          }
        }
        :global(.ant-tabs-nav-operations) {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 36em) {
  .img1Container {
    background-image: url("../../../public/assets/images/yellow-BG-2.svg");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 9rem 0 8rem;
    background-color: transparent;
  }
  .imgBg {
    display: none;
  }
  .imgBgBottom {
    display: none;
  }
  .logo1 {
    bottom: 5rem;
    right: -8px;
    left: unset;
    img {
      width: 171px;
      transform: rotate(200deg);
    }
  }
  .logo2 {
    top: 6rem;
    left: -3%;
    img {
      width: 171px;
      transform: rotate(200deg);
    }
  }
  .contentWrapper {
    h1 {
      font-size: 28px;
      line-height: 42px;
      margin-bottom: 6px;
    }
    h4 {
      font-size: 12px;
      line-height: 18px;
      margin: 0 auto 20px;
      max-width: 394px;
    }
  }
  .content {
    .details {
      h4 {
        font-size: 16px;
        line-height: 24px;
        margin-top: 20px;
        margin-left:-3%;
      }
      p {
        font-size: 12px;
        line-height: 18px;
      }
      h3 {
        font-size: 18px;
        line-height: 27px;
        margin-left:-3%;
      }
    }
  }
  .imgWrapper
  {
    margin-left:8%;
  } 
        
  .tabsWrapper {
    max-width: 280px;
    //padding:10px;
    //text-align:center;
    margin: 0 auto;
    :global(.ant-tabs) {
      :global(.ant-tabs-nav) {
        padding: 6px 8px;
        border-radius: 10px;
        &::before {
          display: none;
        }
        :global(.ant-tabs-nav-wrap) {
          :global(.ant-tabs-tab) {
            :global(.ant-tabs-tab-btn) {
              font-size: 14px;
              line-height: 21px;
              padding: 8px 15px;
              border-radius: 10px;
              margin-left: 35px;
              text-align:center;
              //margin-right: 10px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width:480px)
{
  .tabsWrapper 
  {
    //max-width:340px;
    width:80%;
  }
}

@media only screen and (min-width:640px), (max-width:768px)
{
  .tabsWrapper 
  {
    max-width:100%;
  }
  @media only screen and (max-width:912px)
  {
    .tabsWrapper
    {
      max-width: 90%;
    }
  }
  .contentWrapper
  {
    h1
    {
      position: relative;
      left: 10px; 
    }
  }
}
