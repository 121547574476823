.whatWeDoContainer {
    width:100%;
    margin: 5rem auto;
    h2 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 52px;
        line-height: 2.375rem;
        text-align: center;
        color: #333333;
        margin-bottom: 1.25rem;
    }
    h3 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 2.375rem;
        text-align: left;
        color: #333333;
        margin-bottom: 1.25rem;
        margin-left: 1%;
    }
    .footerList {
        margin-left: 0;
        margin-right: 2%;
        margin-top: -10px;
        padding-top: 1px;
      
       li {
          font-family: Poppins, sans-seri; //fGraphik Light
          margin-bottom: 14px;
        //list-style-type: circle;
          color: black;
          cursor: pointer;
          font-weight: 500;
          font-size: 18px;
          text-align:justify;
        }
      }
    .subTitle {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        line-height: 34px;
        word-spacing: 5px;
        text-align: justify;
        color: #333333;
        width: 60%;
        margin: 0 auto 0.5rem;
    }
    :global(.ant-row) {
        justify-content: center;
    }
    :global(.ant-col) {
        background-color: #ffc410cc;
        border: 2px solid;
        border-color: #ffc410;
        border-radius: 25px;
        min-height: 30.0625rem;
        min-width: 20.75rem;
        // position: relative;
        margin: 1rem 0;
        h2 {
            color: #333;
            font-family: "Poppins", Helvetica;
            font-size: 2rem;
            font-style: normal;
            font-weight: 700;
            margin-top: 1.5rem;
            padding: 0 0.5rem;
        }
        p {
            color: #333;
            font-family: "Poppins", Helvetica;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 500;
            padding: 0 2rem;
            text-align: center;
        }
        :global(.ant-btn) {
            backdrop-filter: blur(5.44px) brightness();
            background-color: #333;
            height: 9%;
            width: 62%;
            border-radius: 4px;
            margin: 2rem auto;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fbcc3f;
            box-shadow: 0 0 20px #ffffff1a, inset 0 0 4px #ffffff4c;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            // position: absolute;
            // bottom: 0;
            // left: 4.0625rem;
        }
        :global(.ant-btn:hover) {
            background-color: white;
            color: #14113a;
        }
    }
    :global(.ant-col:nth-child(2)) {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

@media (max-width: 1024px) {
    .whatWeDoContainer {
        width: 91%;
        .cardContainer
        {
            margin-left: 20px;
        }
        .subTitle {
            width: 80%;
        }
    }
}

@media (max-width: 768px) {
    .whatWeDoContainer
    {
        width: 106%;
        //margin-right: -6.5%;
        .subTitle 
        {
            width: 80%;
            margin-left:7%;
        }
    }
}

@media (max-width: 36rem) {
    .whatWeDoContainer
    {
        width: auto;
        .subTitle 
        {
            width: 75%;
            margin-left: 10%;
        }
        h2
        {
            line-height: 95%;
            font-size: 31px;
            margin-left: -6%;
        }
        .cardContainer
        {
            margin-left: 5px;
        }
    }
}


@media only screen and (max-width:912px)
{
    .whatWeDoContainer
    {
        width: auto;
        position: relative;
        left: 0.5%;
    }
    
}
