.wrapper {
  position: relative;
}
.bgImg {
  background-image: url("../../../public/assets/images/HERO_BG.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 1rem 0 12rem;
}
.heroSlider {
  :global(.slick-prev) {
    width: auto;
    height: auto;
    top: auto;
    left: 42%;
    bottom: -55px;
    z-index: 998;
    &::before {
      background-color: #000000;
      border-radius: 50%;
      background-image: url("../../../public/assets/images/previousBtn.svg");
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
    }
  }
  :global(.slick-next) {
    width: auto;
    height: auto;
    top: auto;
    right: 38%;
    bottom: -55px;
    z-index: 998;
    &::before {
      background-color: #000000;
      border-radius: 50%;
      background-image: url("../../../public/assets/images/previousBtn-right.svg");
      content: "";
      background-size: cover;
      background-repeat: no-repeat;
      width: 40px;
      height: 40px;
      z-index: 999;
      opacity: 1;
      display: block;
    }
  }
  :global(.slick-dots) {
    position: relative;
    z-index: 11;
    bottom: -30px;
    left: 2%;
    button {
      width: 16px;
      height: 16px;
      &::before {
        outline: 1px solid black;
        outline-offset: -2px;
        border-radius: 50px;
        top: -2px;
        font-size: 14px;
        line-height: 22px;
        color: transparent;
        opacity: 1;
      }
    }
  }
  :global(.slick-active) {
    button {
      &::before {
        color: black;
      }
    }
  }
}
.heroDetailsWrapper {
  position: relative;
  .logo {
    position: absolute;
    right: 50px;
    bottom: 0;
  }
}
.content 
{
  display: flex !important;
  justify-content: center;
  align-items: center;
}
h2
{
  text-align:center;
  margin-top: 3%;
  font-size: 46px;
  font-weight: 700;
}

.details {
  max-width: 726px;
  padding: 0 1.25rem;

  h4 {
    font-family: "Poppins";
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
    text-align: left;
    margin-bottom: 20px;
  }
  h2 {
    font-family: "Poppins";
    font-size: 62px;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }
  p {
    font-family: Poppins;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
    max-width: 634px;
  }
}
.btn {
  font-family: "Poppins";
  padding: 14px 38px;
  display: inline-block;
  background: #ffc410;
  cursor: pointer;
  border: 3px solid #ffe698;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}

@media (max-width: 1024px) {
  .content {
    flex-wrap: wrap;
  }
  .imgWrapper {
    margin-top: -4rem;
    img {
      object-fit: contain;
      height: auto;
      margin-left: -0.1%; //3%
      margin-top: 6rem;
      //width: 70%;
      
    }
  }
  .details {
    margin-top: -5rem;
  }
  .heroDetailsWrapper {
    .logo {
      right: 30px;
      img {
        width: 195px;
      }
    }
  }
}
@media (max-width: 768px) {
  .content {
    flex-wrap: wrap;
  }
  .imgWrapper {
    margin-top: -6rem;
    img {
      object-fit: contain;
      height: auto;
      //margin-left: -14%;
    }
  }
  .details {
    margin-top: -7rem;
  }
  .heroDetailsWrapper {
    .logo {
      right: 30px;
      img {
        width: 195px;
      }
    }
  }
  .heroSlider {
    :global(.slick-prev) {
      left: 35%;
      &::before {
        position: relative;
      }
    }
    :global(.slick-next) {
      right: 32%;
      &::before {
        position: relative;
      }
    }
  }
}
@media (max-width: 36em) {
  .bgImg {
    padding: 1rem 0 10rem;
  }
  .imgWrapper {
    margin-top: -45px;
    img {
      width: 98%;
      height: auto;
      margin-left:-1.5%;
    }
  }
  .details {
    margin-top: -45px;
    padding: 0 24px;
    h4 {
      font-size: 14px;
      font-weight: 600;
      line-height: 21px;
      text-align: left;
      margin-bottom: 6px;
    }
    h2 {
      font-size: 28px;
      font-weight: 700;
      line-height: 42px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 6px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 30px;
      max-width: 100%;
    }
  }
  .btn {
    font-size: 14px;
    line-height: 28px;
    padding: 8px 14px;
    color: black;
  }
  .heroDetailsWrapper {
    .logo {
      right: 30px;
      img {
        width: 195px;
      }
    }
  }
  .heroSlider {
    :global(.slick-prev) {
      left: 20%;
    }
    :global(.slick-next) {
      right: 18%;
    }
  }
}
