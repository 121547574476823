.container {
  max-width: 94%;
  //max-height: auto;
  padding: 1.125rem 2rem;
  margin: 0 auto;
  display: flex;
  //justify-content: space-between;
}

.u2img
{
  height: 90px;
  min-width: 400px;
  margin-left: 10%;
  margin-top: 28%;
}

.u2mimg
{
  height: 170px;
  min-width: 400px;
  margin-left: 10%;
  margin-top: 28%;
}

h1 
{
  font-family: "Poppins";
  font-size: 35px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 2%;
  //display: block;
  //margin-left:-1%;
  min-width: 300px;
}

.para
{
  font-size: 25px;
  font-weight: 800;
  position: relative;
  top: 60%;
  left: 200px;
  font-family: 'Poppins', sans-serif;
}
span
{
  img
  {
    position: relative;
    margin-left: 1080px;
    max-height: 60%;
    max-width: 60%;
    bottom: 110px;
  }
}
.logo {
  color: green;
  margin: auto 0;
}

.menuOutlined {
  display: none;
  margin: auto 0;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.navLink {
  text-decoration: none;
  font-size: 20px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin-left: 2.5%; //2rem;
  position:relative;
  left:50%;
  top:12%;
}

.langMenu {
  font-size: 16px;
  font-family: "poppins";
  font-weight: 700;
  color: #14113a;
  margin: 0 2rem;
}

.login {
  :global(.ant-btn) {
    width: 110px;
    height: 47px;
    justify-content: center;
    align-items: center;
    padding: 5px 13px;
    background: #ffc410;
    border: 3px solid #ffe698;
    border-radius: 8px;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #000000;
  }
  :global(.ant-btn:hover) {
    background: white;
    color: #14113a;
    border: 3px solid #14113a;
  }
}

.mobileNavLink {
  display: none;
}

@media screen and (max-width: 991px) {
  .container {
    padding: 1.125rem 1rem;
  }

  .u2img
  {
    position: relative;
    left: 20%;
  }  

  .desktopNavLink {
    display: none;
  }

  .menuOutlined {
    display: block;
    margin-left: 30%;
    margin-bottom: 18%;
  }

  .mobileNavLink {
    display: block;
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    width: 94%;
    margin: 0 auto;
  }

  .navLink {
    margin: 0;
    padding: 1rem 0;
  }

  .login {
    padding-top: 0.5rem;
    
    :global(.ant-btn) {
      padding: 8px;
      margin: 0;
    }
  }

  .langMenu {
    margin: 0;
    padding: 1rem 0;
  }
}

@media (min-width:320px), (max-width:667)
{
  .container
  {
    h1
    {
      //color:darkcyan;
      margin-right: -35px;
      text-align:left;
      font-weight:500;
      position: relative;
      right: 40px;
    }
  }

  span
  {
    img
    {
      position:relative;
      left:-20px;
      min-width:100px;
      height:auto;
    }
  }
  .mobileNavLink
  {
    .para
    {
      display: none;
    }
    span
    {
      img
      {
        //display: block;
        min-width:100px;
        height:auto;
        position:relative;
        left:2px;
      }
    }
  }
  
  @media only screen and (max-width:360px)
  {
    .container
    {
      h1
      {
        //color:darkcyan;
        margin-left: -10px;
        font-weight:900px;
        font-size: 25px;
      }
    }
  } 
}

@media only screen and (min-width:768px), (max-width:912px)
{
  .container
  {
    h1
    {
      //color:darkcyan;
      margin-left: 40px;
      font-weight:1000;
      font-size:28px;
      width:180%;
    }
  }
  .desktopNavLink
  {
      margin-right:100px;
      //display:none;
  }

  .menuOutlined
  {
    margin-top: 5%;
    position: relative;
    left: -14px;
  }

  span
  {
    img
    {
      position:relative;
      left:-8px;
    }
  }
}

@media only screen and (max-width: 540px)
{
  .mobileNavLink
  {
    span
    {
      img
      {
        //min-width:100px;
        //height:auto;
        position:relative;
        background-color: orange;
        margin-bottom:-80px;
        
      }
    }
  }

  .u2img
  {
    position: relative;
    left: -15.4%;
  }

  span
  {
    img
    {
      position:relative;
      right: 10px;
    }
  }
}

@media only screen and (min-width:1000px), (max-width: 1200px)
{
  .desktopNavLink
    {
      position: relative;
      right: 350px;
      background: 'red';
    }
    .para
    {
      position: relative;
      left: 250px;
    }
}

/*@media only screen and (min-width:1200px), (max-width:1600px)
{
  .container
    {
      h1
        {
          color:darkcyan;
          margin-left: -25px;
          font-weight:1000;
          font-size:25px;
          width: 180%;
        }
    }
    .navLink
    {
      font-size: 20px;
    }
  .desktopNavLink
    {
      margin-right:230px;
      margin-top: 20px;
    }
    span
    {
      img
      {
        position:relative;
        left:458px;
        //float:left;
        //min-width:100px;
        //height:auto;
      }
    }
} */