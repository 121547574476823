.wrapper {
  max-width: 1600px;
  margin: 0 auto;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.contentWrapper {
  max-width: 634px;
  position: relative;
  top:-80px;
  h4 {
    font-family: "Poppins";
    font-size: 25px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
    margin-left: 20px;
    
  }
  h2 {
    font-family: "Poppins";
    font-size: 52px;
    font-weight: 700;
    line-height: 68px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 30px;
    color: red;
  }
  p {
    font-family: "Poppins";
    font-size: 17px;
    font-weight: 600;
    line-height: 34px;
    word-spacing: 5px;
    text-align: justify;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}
.btn {
  font-family: "Poppins";
  padding: 14px 38px;
  display: inline-block;
  background: #ffc410;
  cursor: pointer;
  border: 3px solid #ffe698;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
}
.imgWrapper 
{
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 80%;
  margin-top: -5%;
  img 
  {
    width: 120%;
    height: auto;
  }
}

@media (max-width: 1024px) 
{
  .container 
  {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .contentWrapper
  {
    p
    {
      font-family: "Poppins";
      font-size: 17px;
      font-weight: 600;
      line-height: 34px;
      word-spacing: 5px;
      text-align: justify;
      margin-bottom: 20px;
      margin-left: 20px;
    }
  }
  .imgWrapper 
  {
    max-width: 70%;
  }
}

@media (max-width: 768px) 
{
  .container 
  {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .contentWrapper
  {
    p
    {
      font-family: "Poppins";
      font-size: 17px;
      font-weight: 600;
      line-height: 34px;
      word-spacing: 5px;
      text-align: justify;
    }
  }
  .imgWrapper 
  {
    max-width: 70%;
  }
}

@media (max-width: 36em) 
{
  .container 
  {
    padding: 0 1.25rem;
    flex-wrap: wrap;
    flex-direction: column;
  }
  .imgWrapper 
  {
    max-width: 80%;
  }
  .contentWrapper 
  {
    h4 {
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 6px;
    }
    h2 {
      font-size: 24px;
      margin-bottom: 6px;
      line-height: 42px;
    }
    p {
      font-size: 12px;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (max-width:480px)
{
  .contentWrapper 
  {
    h4
    {
      font-size: 20px;
      font-weight: 900;
      margin-left: 1px;
    }
    p 
    {
      font-size: 14px;
      margin-bottom: 20px;
      margin-left: 1px;
    }
  }
}

@media only screen and (max-width:912px)
{
  .contentWrapper 
  {
    h4
    {
      font-size: 20px;
      font-weight: 900;
      margin-left:1px;
    }
    p 
    {
      font-size: 14px;
      margin-bottom: 20px;
      margin-left: 1px;
    }
  }
}

